.profile {
    .profile_info_block {
        width: 100%;
        padding: 25px 10px;
        background: #F2F2F2;
        border-radius: 0 0 20px 20px;
        .profile__header {
            gap: 12px;
            display: flex;
            align-items: end;
            p {
                color: #606060;
                padding-bottom: 10px;
            }
        }
        .profile__info_services {
            gap: 7px;
            display: flex;
            margin-top: 10px;
            align-items: center;
            justify-content: center;
            .profile__info_service_current, .profile__info_service_archive {
                display: flex;
                width: 185px;
                height: 90px;
                padding: 10px;
                text-align: center;
                background: #fff;
                align-items: center;
                border-radius: 20px;
                flex-direction: column;
                justify-content: center;
                .count_services {
                    width: 35px;
                    height: 35px;
                    display: flex;
                    border-radius: 50%;
                    align-items: center;
                    background: #adf8ff;
                    justify-content: center;
                    p {
                        margin-top: 0;
                        font-weight: 400;
                        position: absolute;
                    }
                }
                p {
                    color: #000;
                    margin-top: 5px;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }
    .profile_menu {
        padding: 0 20px 100px;
        margin-top: 40px;
        .profile_menu__link {
            width: 100%;
            height: 48px;
            display: flex;
            padding: 0 20px;
            border-radius: 8px;
            align-items: center;
            background: #F2F2F2;
            justify-content: space-between;
            p {
                font-size: 17px;
                color: #313131;
            }
            svg {
                color: #313131;
            }
            &:first-child {
                margin-bottom: 10px;
            }
        }
    }
}