.message__text_sender {
    width: 250px;
    height: min-content;
    margin-top: 25px;
    padding: 25px 15px;
    justify-self: right;
    background: #83e2ff;
    border-radius: 10px 10px 0px 10px;
    p {
        color: #383838;
    }
    &:first-child {
        margin-top: 0px;
    }
}
.message__text_receiver{
    width: 250px;
    margin-top: 25px;
    height: min-content;
    padding: 25px 15px;
    justify-self: left;
    background: #efefef;
    border-radius: 10px 10px 10px 0px;
    p {
        color: #383838;
    }
}