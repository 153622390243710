.chat__receiver_message_img {
    width: 250px;
    margin-top: 25px;
    justify-self: left;
}

.chat__send_message_img {
    width: 250px;
    margin-top: 25px;
    justify-self: right;
}