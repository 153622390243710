.terms_action {
    margin-top: 40px;
    ol {
        li {
            color: #555;
            font-size: 17px;
            margin-top: 20px;
            &:first-child {
                margin-top: 0px;
            }
        }
    }
}