.profile__pets {
    margin-top: 25px;
    text-align: center;
    h1 {
        color: #000;
        font-size: 30px;
    }
    svg {
        margin-top: 5px;
        font-size: 50px;
        color: #8DE719;
    }
    .profile__pets_pets {
        gap: 5px;
        display: grid;
        margin-top: 5px;
        align-items: center;
        grid-template-columns: repeat(6, 1fr);
        .profile__pets_pet {
            gap: 2px;
            display: flex;
            align-items: center;
            flex-direction: column;
            .pet_img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                position: relative;
                background: #fff;
                img {
                    width: 30px;
                    height: 30px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    position: absolute;
                }
            }
            p {
                color: #000;
            }
        }
    }
}