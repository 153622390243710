.my_documents {
    margin-top: 15px;
    text-align: center;
    padding: 0 0px 80px;
    h1 {
        font-size: 20px;
        color: #000;
        font-weight: 600;
        line-height: 30px;
        font-style: normal;
        text-transform: uppercase;
        font-optical-sizing: auto;
        font-family: "Inter", sans-serif;
        font-variation-settings: "slnt" 0;
    }
    .document {
        gap: 0.75rem;
        display: flex;
        margin-top: 2rem;
        color: #1b1b1b;
        padding: 20px 10px;
        align-items: center;
        border-radius: 1.0625rem;
        border: 0.0625rem solid #ECECEC;
    
        svg {
            width: 37px;
            height: 37px;
            color: #9c9c9c;
        }
        p {
            text-align: left;
            text-decoration: none;
        }
    }   
}