.chat {
    display: flex;
    flex-direction: column;
    height: 100vh;
    .chat__header {
        width: 100%;
        height: 50px;
        background: #e6e6e6;
    }
    .chat__messages {
        display: grid;
        flex-grow: 1;
        overflow-y: auto; 
        padding: 20px 5px;
        grid-auto-rows: max-content;
        background: url('../../assets/img/chat/background.png');
    }
    .uploaded_images {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        background: #e6e6e6;
        justify-content: center;
    }
    .record_handler {
        display: flex;
        padding: 26px 18px;
        align-items: center;
        justify-content: space-between;
        svg {
            width: 20px;
            height: 20px;
            color: #000;
        }

        .audio_record {
            display: flex;
            align-items: center;
            gap: 15px;
            .audio_record__header {}
            .sound_wave {
                display: none;
            }
        }
    }
    .chat__control_block {
        width: 100%;
        display: grid;
        padding: 7px 0;
        text-align: center;
        align-items: center;
        background: #e6e6e6;
        grid-template-columns: 12% 1fr 12%;
        span {
            justify-content: center;
        }
        svg {
            font-size: 20px;
            background: none;
        }
        input {
            color: #858585;
            padding: 20px 5px;
            border-radius: 10px;
            border: 1px solid #858585;
        }
    }
}