.menu_link {
    display: grid;
    padding: 12px 0px;
    background: #fff;
    border-radius: 5px;
    align-items: center;
    text-decoration: none;
    justify-items: center;
    grid-template-columns: 14% 3% 1fr 14%;
    
    div {
        &:first-child {
            svg, img {
                width: 22px;
                height: 22px;
                color: #0D3C65;
            }
        }
        &:last-child {
            svg {
                width: 22px;
                height: 22px;
                color: #0D3C65;
            }
        }
        &:nth-child(2) {
            width: 1px;
            height: 45px;
            background: #d6d6d6;
        }
        &:nth-child(3) {
            p {
                color: #000;
                font-size: 15px;
            }
        }
    }
}