.pets_add_medical {
    margin-top: 50px;
    form {
        .sterilization, .place, .conditions, .chip_number {
            margin-bottom: 10px;
        }
        .button_medical_form {
            margin-top: 70px;
        }
    }
}