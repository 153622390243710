.questions__question {
    margin-top: 17px;
    .question__main {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            color: #000;
            margin-top: 0;
            font-size: 24px;
        }
        svg {
            color: #0D3C65;
            font-size: 35px;
        }
    }
    .question__description {
        width: 285px;
        color: #383838;
        p {
            line-height: 22px;
        }
    }
}