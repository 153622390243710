.invite_friend {
    margin-top: 30px;
    padding-bottom: 100px;
    p {
        width: 300px;
        margin: 0 auto;
        font-size: 17px;
        text-align: center;
    }
    img {
        width: 100%;
        margin-bottom: 10px;
    }
    .how_work {
        margin-top: 23px;
        text-align: center;

        h2 {
            font-size: 22px;
            font-weight: 600;
        }
        ol {
            color: #555;
            font-size: 17px;
            text-align: left;
            list-style: inside decimal;
            margin: 20px auto 20px auto;
            li {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        a {
            color: #555;
        }
    }
}