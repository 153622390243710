.services_pay {
    margin-top: 20px;
    .services_pay__price {
        h2 {
            color: #000;
            font-size: 22px;
            font-weight: bold;
            text-align: center;
        }
    }
    .services_pay__promocode {
        p {
            color: #000;
            font-size: 16px;
            margin-top: 15px;
            font-weight: 500;
        }
        form {
            .service_pay__promocode__block {
                gap: 10px;
                padding: 4px;
                display: flex;
                margin-top: 4px;
                border-radius: 8px;
                justify-content: center;
                border: 1px solid #858585;
                input {
                    width: 100%;
                }
                button {
                    width: 85px;
                    height: 40px;
                    color: #fff;
                    padding: 0 5px;
                    border-radius: 8px;
                    background: rgb(13, 60, 101);;
                }
            }
            .email_title {
                margin-top: 10px;
                gap: 5px;
                display: flex;
                align-items: center;
            }    
            .service_pay__email_block {
                gap: 10px;
                padding: 4px;
                display: flex;
                margin-top: 4px;
                border-radius: 8px;
                justify-content: center;
                border: 1px solid #858585;
                input {
                    width: 100%;
                    height: 40px;
                }
            }
        }
    }
    .services_pay__btn {
        button {
            width: 100%;
            color: #fff;
            display: flex;
            font-size: 20px;
            margin-top: 30px;
            font-weight: 700;
            font-weight: 500;
            line-height: 22px;
            padding: 14px 24px;
            border-radius: 14px;
            align-items: center;
            border-radius: 30px;
            background: #8DE719;
            justify-content: center;
        }
    }
}