.home_page__service {
    gap: 18px;
    display: grid;
    padding: 15px 12px;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #858585;
    grid-template-columns: 19% 3% 1fr 11%;
    .line {
        width: 1px;
        height: 70px;
        background: #d6d6d6;
    }
    div {
        &:nth-child(3) {
            display: grid;
            grid-gap: 10px;
            align-items: center;
            justify-content: left;
            .description {
                width: 145px;
                p {
                    font-size: 13px;
                    color: #555555;
                }
            }
            .price {
                p {
                    color: #000;
                    font-size: 20px;
                    font-weight: 500;
                }
            }
        }
        &:first-child {
            img {
                width: 70px;
                height: 70px;                    
            }
        }
        &:last-child {
            svg, img {
                width: 33px;
                height: 33px;
                color: #8DE719;
            }
        }
    }
}