.support {
    margin-top: 16px;
    h2 {
        font-size: 30px;
        text-align: left;
    }
    p {
        font-size: 17px;
        margin-top: 12px;
        color: #383838;
        text-align: left;
    }
    .service_support {
        margin-top: 30px;
        p {
            width: 270px;
        }
        .service_support__contacts {
            margin-top: 20px;
            div {
                display: flex;
                align-items: center;
                justify-content: left;
                gap: 15px;
                p {
                    margin-top: 0;
                }
                &:first-child {
                    svg {
                        color: #2298ff;
                    }
                }
                &:last-child {
                    margin-top: 10px;
                    svg {
                        color: #8DE719;
                    }
                }
            }
        }
    }
}