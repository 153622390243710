.about {
    p {
        margin-top: 0;
        font-size: 17px;
        margin-top: 12px;
        color: #383838;
        text-align: left;
    }
    .about__links {
        .link {
            display: flex;
            margin-top: 20px;
            align-items: center;
            justify-content: space-between;

            p {
                width: 220px;
                color: #000;
                margin-top: 0;
                font-size: 24px;
            }
            svg {
                width: 33px;
                height: 33px;
                color: #8DE719;
            }
        }
    }
    .about__app {
        p {
            margin-top: 0;
            font-size: 17px;
            margin-top: 12px;
            color: #383838;
        }
        a {
            display: block;
            font-size: 17px;
            margin-top: 20px;
            color: #383838;
        }
    }
}