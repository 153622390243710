.navbar {
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 20px;
    display: flex;
    position: fixed;
    text-align: center;
    align-items: center;
    background: #fafafa;
    justify-content: space-around;
    a {
        div {
            height: 25px;
            position: relative;
        }
        svg, img {
            width: 100%;
            height: 25px;
            display: block;
            color: #6f6e6e;
        }
        p {
            font-size: 12px;
            color: #6f6e6e;
        }
    }
    .logo {
        width: 100px;
        .circle {
            left: 50%;
            bottom: 0;
            width: 80px;
            height: 80px;
            z-index: 100;
            border-radius: 50%;
            background: #fff;
            position: absolute;
            transform: translate(-50%, -10%);
            box-shadow: 0px 0px 20px 2px #c6c8c9;

            img {
                width: 70px;
                height: 26px;
            }
        }
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 101;
            transform: translate(-50%, -50%);
        }
    }
    .active {
        img,svg,p {
            color: #8DE719;
        }
    }
}