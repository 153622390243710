.chats {
    margin-top: 30px;
    .current_chats {
        div {
            gap: 25px;
            width: 160px;
            display: flex;
            align-items: center;
            p {
                margin-top: 0;
                font-size: 30px;
                text-align: left;
            }
            .inactive_text {
                font-size: 18px;
            }
        }
        .my_packages {
            width: 100%;
            display: grid;
            grid-gap: 30px;
            margin-top: 15px;
            .my_package {
                display: grid;
                width: 100%;
                padding: 25px 15px;
                border-radius: 10px;
                border: 1px solid #000;
                grid-template-columns: 1fr 10%;
                box-shadow: 0px 0px 20px 2px #c6c8c9;
                .active_package_text {
                    gap: 12px;
                    width: 100%;
                    align-items: start;
                    flex-direction: column;
                    p {
                        &:first-child {
                            font-size: 18px;
                        }
                        &:last-child {
                            color: #646465;
                            font-size: 14px;
                        }
                    }
                }
                svg {
                    width: 33px;
                    height: 33px;
                    color: #8DE719;
                }
            }
        }
        .description {
            font-size: 17px;
            margin-top: 20px;
            color: #383838;
            padding-left: 65px;
        }
    }
    .arhive_chats {
        margin-top: 20px;
        div {
            gap: 25px;
            width: 160px;
            display: flex;
            align-items: center;
            p {
                margin-top: 0;
                font-size: 30px;
                text-align: left;
            }
        }
        .my_packages {
            width: 100%;
            display: grid;
            grid-gap: 10px;
            margin-top: 15px;
            .my_package {
                display: grid;
                width: 100%;
                padding: 40px 15px;
                border-radius: 10px;
                border: 1px solid #000;
                grid-template-columns: 1fr 10%;
                box-shadow: 0px 0px 20px 2px #c6c8c9;
                p {
                    font-size: 18px;
                }
                svg {
                    width: 33px;
                    height: 33px;
                    color: #8DE719;
                }
            }
        }
        .description {
            font-size: 17px;
            margin-top: 35px;
            color: #383838;
            text-align: center;
        }
    }
    button {
        margin-top: 35px;
    }
}