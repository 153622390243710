.home_page {
    .home_page__action_block {
        padding: 20px;
        background: #F2F2F2;
        border-radius: 0 0 20px 20px;
        .home__user_block {
            gap: 25px;
            display: flex;
            align-items: center;
            justify-content: left;
            .name {
                font-size: 25px;
                font-weight: 600;
                color: #0D3C65;
            }
        }
        .home__menu_block {
            gap: 18px;
            display: grid;
            margin-top: 20px;
            grid-template-rows: 1fr 1fr;
        }
    }
    .home_page__services_block {
        background: #fff;
        position: relative;
        padding: 30px 10px 110px;
        h1 {
            font-size: 25px;
        }
        .home_page__services {
            display: grid;
            grid-gap: 15px;
            margin-top: 40px;
            grid-template-rows: auto;
        }
    }
}
