.avatar_block {
    position: relative;
    div {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 1px solid #0D3C65;
    }
    svg {
        color: #fff;
    }
}