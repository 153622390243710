.pets_add {
    margin-top: 50px;
    form {
        .type, .breed, .nickname, .date {
            margin-bottom: 10px;
        }
        .button_pets_form {
            margin-top: 70px;
        }
    }
    .pets__flex {
        display: flex;
        justify-content: space-between;

        .weight, .sex {
            margin-bottom: 10px;
        }
    }
    .pets__medical_link {
        gap: 5px;
        display: flex;
        align-items: center;
        justify-content: left;
        p {
            font-size: 13px;
            color: #383838;
        }
        svg {
            font-size: 9px;
        }
    }
}