.pets {
    padding: 0 30px;
    margin-top: 50px;
    text-align: center;
    img {
        width: 135px;
        height: 135px;
    }
    p {
        width: 270px;
        color: #000;
        margin: 0 auto;
        font-size: 17px;
    }
    button {
        width: 100%;
        color: #fff;
        display: flex;
        font-size: 20px;
        margin-top: 30px;
        font-weight: 700;
        font-weight: 500;
        line-height: 22px;
        padding: 14px 24px;
        border-radius: 14px;
        align-items: center;
        border-radius: 30px;
        background: #8DE719;
        justify-content: center;
    }
}

.pets_list {
    justify-content: center;
    // padding: 0px 0px 40px 0px;
    grid-gap: 5px;
    display: grid;
    grid-template-rows: auto;
    .pet {
        width: 100%;
        display: grid;
        padding: 15px 6px;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #858585;
        grid-template-columns: 1fr 1fr 1fr;
        .pet__img {
            width: 40px;
            height: 40px;
            position: relative;
            border-radius: 50%;
            background: #efefef;
            img {
                top:50%;
                left:50%;
                transform:translate(-50%, -50%);
                width: 30px;
                height: 30px;
                position: absolute;
            }
        }
        .pet__name {
            p {
                &:first-child {
                    font-weight: 600;
                }
                &:last-child {

                }
            }
        }
        svg {

        }
    }
    button {
        width: 280px;
        margin: 30px auto 90px;
    }
}