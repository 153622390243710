.pet_profile {
    .profile__info_block {
        padding: 20px 20px 30px;
        background: #F2F2F2;
        .pet_profile__info {
            gap: 20px;
            display: flex;
            align-items: center;
            .pet_img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                position: relative;
                background: #fff;
                img {
                    top: 50%;
                    left: 50%;
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    transform: translate(-50%, -50%);
                }
            }
            .pet_name {
                display: grid;
                grid-gap: 5px;
                .pet_name__name {
                    font-size: 17px;
                    font-weight: 600;
                }
                .pet_name__birth_date {
                    color: #606060;
                }
                a {
                    gap: 3px;
                    display: flex;
                    align-items: center;
                    p {
                        color: #000;
                    }
                    svg {
                        color: #000;
                        font-size: 10px;
                    }
                }
            }
        }
        .pet_profile__consultation {
            display: grid;
            margin-top: 20px;
            padding: 30px 12px;
            background: #fff;
            border-radius: 5px;
            align-items: center;
            text-decoration: none;
            justify-items: flex-start;
            grid-template-columns: 1fr 10%;

            p {
                color: #000;
                font-size: 20px;
            }
            svg {
                font-size: 22px;
            }
        }
        .pet_profile__consultation_date {
            display: grid;
            margin-top: 25px;
            padding: 12px 0px;
            background: #fff;
            border-radius: 5px;
            align-items: center;
            text-decoration: none;
            justify-items: center;
            grid-template-columns: 14% 3% 1fr 20%;
            
            div {
                &:first-child {
                    svg, img {
                        width: 22px;
                        height: 22px;
                        color: #000;
                    }
                }
                &:last-child {
                    svg {
                        width: 22px;
                        height: 22px;
                        color: #000;
                    }
                }
                &:nth-child(2) {
                    width: 1px;
                    height: 45px;
                    background: #d6d6d6;
                }
                &:nth-child(3) {
                    p {
                        color: #000;
                        font-size: 15px;
                    }
                }
            }
        }
    }
    .pet_profile__history_consultations {
        padding: 20px 20px 30px;
        h2 {
            font-size: 25px;
            font-weight: 600;
        }
    }
}