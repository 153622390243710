.more {
    margin-top: 50px;

    .link_menu {
        width: 100%;
        height: 50px;
        display: flex;
        padding: 0 20px;
        margin-top: 17px;
        border-radius: 10px;
        align-items: center;
        justify-content: left;
        box-shadow: 0px 0px 8px 1px #c6c8c9;
        p {
            font-size: 18px;
            color: #3d3b3b;
        }
    }
}