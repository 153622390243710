.service_info {
    h1 {
        font-size: 26px;
        text-align: center;
    }
    img {
        width: 100%;
        margin-top: 45px;
        border-radius: 50%;
    }
    p {
        font-size: 22px;
        font-weight: 600;
        margin-top: 40px;
        text-align: center;
    }
    button {
        margin-top: 25px;
    }
}